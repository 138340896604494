import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Embedding a servlet container";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RequiredDependencies = makeShortcode("RequiredDependencies");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "embedding-a-servlet-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#embedding-a-servlet-container",
        "aria-label": "embedding a servlet container permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Embedding a servlet container`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#embedding-apache-tomcat"
        }}>{`Embedding Apache Tomcat`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#embedding-jetty"
        }}>{`Embedding Jetty`}</a></li>
    </ul>
    <p>{`You can make Armeria serve your JEE web application on the same JVM and TCP/IP port by embedding
`}<a parentName="p" {...{
        "href": "https://tomcat.apache.org/"
      }}>{`Apache Tomcat`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.eclipse.org/jetty/"
      }}>{`Jetty`}</a>{`.
Neither Tomcat nor Jetty will open a server socket or accept an incoming connection.
All HTTP requests and responses go through Armeria. As a result, you get the following bonuses:`}</p>
    <ul>
      <li parentName="ul">{`Your webapp gets HTTP/2 support for free even if your servlet container does not support it.`}</li>
      <li parentName="ul">{`You can run your RPC services on the same JVM and port as your webapp with no performance loss.`}</li>
    </ul>
    <h2 {...{
      "id": "embedding-apache-tomcat",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#embedding-apache-tomcat",
        "aria-label": "embedding apache tomcat permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Embedding Apache Tomcat`}</h2>
    <p>{`You need the `}<inlineCode parentName="p">{`armeria-tomcat9`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-tomcat9'
    }]} mdxType="RequiredDependencies" />
    <p>{`Then, add a `}<a parentName="p" {...{
        "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
      }}>{`type://TomcatService`}</a>{` to a `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.tomcat.TomcatService;

ServerBuilder sb = Server.builder();

sb.serviceUnder("/tomcat/api/rest/v2/",
                TomcatService.forCurrentClassPath("/webapp"));

sb.serviceUnder("/tomcat/api/rest/v1/",
                TomcatService.forFileSystem("/var/lib/webapps/old_api.war"));
`}</code></pre>
    <p>{`For more information, please refer to the API documentation of `}<a parentName="p" {...{
        "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
      }}>{`type://TomcatService`}</a>{` and
`}<a parentName="p" {...{
        "href": "type://TomcatServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatServiceBuilder.html"
      }}>{`type://TomcatServiceBuilder`}</a>{`.`}</p>
    <h2 {...{
      "id": "embedding-jetty",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#embedding-jetty",
        "aria-label": "embedding jetty permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Embedding Jetty`}</h2>
    <p>{`You need `}<inlineCode parentName="p">{`armeria-jetty9`}</inlineCode>{`, additional `}<inlineCode parentName="p">{`jetty`}</inlineCode>{` dependencies and bootstrap code due to its modular design:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }, {
      groupId: 'org.eclipse.jetty',
      artifactId: 'jetty-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-jetty9'
    }, {
      groupId: 'org.eclipse.jetty',
      artifactId: 'jetty-webapp'
    }, {
      groupId: 'org.eclipse.jetty',
      artifactId: 'jetty-annotations'
    }, {
      groupId: 'org.eclipse.jetty',
      artifactId: 'apache-jsp'
    }, {
      groupId: 'org.eclipse.jetty',
      artifactId: 'apache-jstl'
    }]} mdxType="RequiredDependencies" />
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.jetty.JettyService;
import org.eclipse.jetty.annotations.ServletContainerInitializersStarter;
import org.eclipse.jetty.apache.jsp.JettyJasperInitializer
import org.eclipse.jetty.plus.annotation.ContainerInitializer
import org.eclipse.jetty.util.resource.Resource;
import org.eclipse.jetty.webapp.WebAppContext;

ServerBuilder sb = Server.builder();

sb.serviceUnder("/jetty/api/rest/v2/",
                JettyService.builder()
                            .handler(newWebAppContext("/webapp"))
                            .build());

static WebAppContext newWebAppContext(String resourcePath) throws MalformedURLException {
    final WebAppContext handler = new WebAppContext();
    handler.setContextPath("/");
    handler.setBaseResource(Resource.newClassPathResource(resourcePath));
    handler.setClassLoader(/* Specify your class loader here. */);
    handler.addBean(new ServletContainerInitializersStarter(handler), true);
    handler.setAttribute(
            "org.eclipse.jetty.containerInitializers",
            Collections.singletonList(
                    new ContainerInitializer(new JettyJasperInitializer(), null)));
    return handler;
}
`}</code></pre>
    <p>{`For more information, please refer to the API documentation of `}<a parentName="p" {...{
        "href": "type://JettyService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyService.html"
      }}>{`type://JettyService`}</a>{` and
`}<a parentName="p" {...{
        "href": "type://JettyServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyServiceBuilder.html"
      }}>{`type://JettyServiceBuilder`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      